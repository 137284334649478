import React, { useEffect } from 'react';
import './AnswerItem.css';
import useQuizStore from '../../../utils/stores/useQuizStore';

const SummaryAnswerItem = ({ item }) => {
    const { handleUpdateComment, handleUpdateAnswer, quiz, isYours, isInspector } = useQuizStore();

    const {
        category_name: categoryName,
        answers
    } = item;

    const answerValuesToLetters = {
        '5.0': 'A',
        '4.0': 'B',
        '3.0': 'C',
        '1.5': 'D',
        '0.0': 'E'
    };

    const renderAnswer = (answer) => {
        const answerKey = parseFloat(answer).toFixed(1);
        return answerValuesToLetters[answerKey] || '?';
    };

    return (
        <div className="answer-item">
            <div className="question-header">
                <p>{categoryName}</p>
            </div>
            <div className="question-content">
                {answers?.map((answer, index) => (
                    <div className='question-row' key={index}>
                        <div className="question-section">
                            <div className="question-title">
                                <div className='question-info'>
                                    <span>{answer.staff.question_name}</span>
                                </div>
                                <div className="question-answer">
                                    <span>Оценка сотрудника</span>
                                    <div className={`${answer.staff.answer ? 'staff-answer' : 'empty-answer'}`}>
                                        {answer.staff.answer ? renderAnswer(answer.staff.answer) : '?'}
                                    </div>
                                </div>
                            </div>
                            {answer.staff.comment ?
                                <div className="question-comment">
                                    <p style={{ color: '#666666' }}>—{quiz.full_name}</p>
                                    <p>{answer.staff.comment}</p>
                                </div>
                                :
                                <div className="question-comment">
                                    <p>Сотрудник не оставил комментарий</p>
                                </div>
                            }
                        </div>
                        {answer.inspector && (
                            <div id='inspector-section' className="question-section">
                                <div className="question-title">
                                    <div className='question-info'>
                                        <span>{answer.inspector.question_name}</span>
                                    </div>
                                    <div className="question-answer">
                                        <span>Оценка руководителя</span>
                                        <div className={`${answer.inspector.answer ? 'staff-answer' : 'empty-answer'}`}>
                                            {parseFloat(answer.inspector.answer).toFixed(0) ? renderAnswer(answer.inspector.answer) : '?'}
                                        </div>
                                    </div>
                                </div>
                                {answer.inspector.comment ?
                                    <div className="question-comment">
                                        <p style={{ color: '#666666' }}>—{quiz.full_name_inspector}</p>
                                        <p>{answer.inspector.comment}</p>
                                    </div>
                                    :
                                    <div className="question-comment">
                                        <p>Руководитель не оставил комментарий</p>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SummaryAnswerItem;
