import { motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import api from '../../utils/Api';
import loadingGif from '../../utils/images/loading.gif';
import arrow_left from '../../utils/images/arrow_left.svg';
import './ReportModule.css';
import { useNavigate } from 'react-router-dom';

const ReportModule = () => {
    const [quarter, setQuarter] = useState('');
    const [year, setYear] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const quarters = [
        { label: '1 квартал', value: 'Q1', date: '-03-31' },
        { label: '2 квартал', value: 'Q2', date: '-06-30' },
        { label: '3 квартал', value: 'Q3', date: '-09-30' },
        { label: '4 квартал', value: 'Q4', date: '-12-15' },
    ];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setYear(currentYear); // Инициализируем текущий год
    }, []);

    const handleQuarterChange = (e) => {
        setQuarter(e.target.value);
    };

    const handleYearChange = (e) => {
        setYear(e.target.value);
    };

    const getFormattedDate = () => {
        const selectedQuarter = quarters.find(q => q.value === quarter);
        if (selectedQuarter && year) {
            return `${year}${selectedQuarter.date}`;
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedDate = getFormattedDate();
        if (formattedDate) {
            setLoading(true);
            try {
                const token = localStorage.getItem('jwt');
                const response = await fetch(`${api._baseUrl}/quiz/download_full_assessment_results/?quarter=${encodeURIComponent(formattedDate)}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                });

                if (response.ok) {
                    const blob = await response.blob();
                    saveAs(blob, 'report.xlsx');
                } else {
                    console.error('Error fetching report:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching report:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const YearDropdown = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 21 }, (_, i) => currentYear - 10 + i);

        return (
            <label>
                Выберите год:
                <select 
                    style={{ width: '130px' }} 
                    value={year} 
                    onChange={handleYearChange} 
                    required 
                    disabled={loading}
                >
                    {years.map((y) => (
                        <option key={y} value={y}>
                            {y}
                        </option>
                    ))}
                </select>
            </label>
        );
    };

    return (
        <motion.div
            className='report-module__div'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <div 
                className="report-module__button-div"
                type="button"
                onClick={() => navigate('/profile')}
            >
                <img className="quiz-module__button" src={arrow_left} alt="arrow left" />
                <p>Назад</p>
            </div>
            <form onSubmit={handleSubmit}>
                <p style={{ fontWeight: 'bold' }}>Выгрузка отчёта</p>
                <div>
                    <label>
                        Выберите квартал:
                        <select value={quarter} onChange={handleQuarterChange} required disabled={loading}>
                            <option value="">Выберите квартал</option>
                            {quarters.map(q => (
                                <option key={q.value} value={q.value}>{q.label}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div>
                    <YearDropdown />
                </div>
                <button
                    type="submit"
                    disabled={loading}
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                    {loading ? <img src={loadingGif} alt="Loading..." style={{ width: '35px', height: '35px' }} /> : 'Отправить'}
                </button>
            </form>
        </motion.div>
    );
};

export default ReportModule;
